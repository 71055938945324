import React from 'react';

import isString from 'lodash-es/isString';
import {
  Dialog,
  DialogProps,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import { useIntl } from 'ha/i18n';
import { Box, IconButton, units } from '@hbf/dsl/legacy';

import { usePage } from 'ha/modules/Page/contexts/PageContext';
import { CloseIcon } from 'ha/ui/Icons';
import { useMediaQuery } from 'ha/helpers/MediaQuery';
import { track } from '@hbf/analytics';

interface Props {
  classes?: DialogProps['classes'];
  /** TODO: Replace by "open" prop. */
  show: boolean;
  open?: boolean;
  /** Name of the Dialog. Used to track "Open dialog" events. */
  name?: string;
  onClose?: () => void;
  onEnter?: () => void;
  title?: string | JSX.Element;
  titleClass?: string;
  content?: string | React.ReactNode;
  fullWidth?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg';
  dialogActions?: JSX.Element;
  disableBackdropClick?: boolean;
  disableEscapeKeyDown?: boolean;
  fullScreen?: boolean;
  dividers?: boolean;
  noContentPadding?: boolean;
  contentClass?: string;
  keepMounted?: boolean;
  PaperProps?: DialogProps['PaperProps'];
  dataTestLocator?: string;
}

const useStyles = makeStyles()(theme => ({
  actions: {
    padding: units.yx(1, 1.5),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  noPadding: {
    padding: 0,
  },
}));

export const DialogNext: React.FC<Props> = ({
  show: showProp,
  open: openProp,
  name,
  onClose,
  onEnter = undefined,
  title,
  titleClass,
  content,
  contentClass,
  maxWidth,
  dialogActions,
  fullScreen,
  dividers = false,
  noContentPadding = false,
  dataTestLocator,
  ...props
}) => {
  const { classes, cx } = useStyles();
  const { T } = useIntl();
  const { sm } = useMediaQuery();
  const open = showProp || !!openProp;
  const { name: pageName, category } = usePage();

  React.useEffect(() => {
    if (name && open) {
      track('Open dialog', {
        name,
        pageName,
        pageCategory: category,
      });
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen !== undefined ? fullScreen : !sm}
      onClose={onClose}
      maxWidth={maxWidth || 'xs'}
      data-test-locator={
        dataTestLocator ? `${dataTestLocator} Dialog` : 'Dialog Container'
      }
      {...props}
      TransitionProps={{
        onEnter,
      }}
    >
      {title || onClose ? (
        <DialogTitle className={titleClass}>
          <Box pr={2}>{title}</Box>
          {onClose && (
            <IconButton
              className={classes.closeButton}
              aria-label={T('close')}
              onClick={onClose}
              data-test-locator={
                dataTestLocator
                  ? `${dataTestLocator} Close Button`
                  : 'Dialog Close Button'
              }
              size="large"
            >
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
      ) : null}

      {content ? (
        <DialogContent
          dividers={dividers}
          className={cx(
            { [classes.noPadding]: noContentPadding },
            contentClass,
          )}
        >
          {isString(content) ? (
            <DialogContentText>{content}</DialogContentText>
          ) : (
            content
          )}
        </DialogContent>
      ) : null}

      {dialogActions && (
        <DialogActions className={classes.actions}>
          {dialogActions}
        </DialogActions>
      )}
    </Dialog>
  );
};
